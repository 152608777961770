import { waitForFindify } from '@findify/react-bundle';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router';

// PROVIDE USEFINDIFY
export const FindifyActiveContext = React.createContext(null);

// LOADER FOR THE DIFFERENT CHANNELS
export const FindifyLoader = ({ children }) => {
  const { selectedChannel } = useContext(ChannelContext);
  console.log(selectedChannel);

  const findifyAccounts = {
    channel_1: 'finntack.eu', // EU
    channel_2: 'finntack.se', // SE
    channel_3: 'finntack.no', // NO
    channel_4: 'finntack.fi', // FI
    channel_5: 'finntack.fr', // FR
    channel_11: 'finntack.dk', // DK
    channel_12: 'finntack.de', // DE
    channel_13: 'finntack.uk' // UK
  };
  const channelId = selectedChannel.id;
  let accountId = findifyAccounts['channel_' + channelId];

  // // Disables findify for B2B (B2B:s not in findifyAccounts dict)
  // if (!accountId) {
  //   return null;
  // }

  // USE STAGING ACCOUNT FOR TEST ENVIRONMENT
  if (
    accountId &&
    typeof window !== 'undefined' &&
    window.location.host.match(/(dev\.jetshop\.se)|localhost/i)
    // window.location.host.match(/(dev\.jetshop\.se)/i)
  ) {
    accountId = 'finntack-staging.eu';
  }

  return (
    <FindifyActiveContext.Provider value={!!accountId}>
      {selectedChannel && accountId && (
        <Helmet>
          <script
            id="findify-script"
            src={`https://assets.findify.io/${accountId}.min.js`}
            async="true"
            defer="true"
            type="text/javascript"
          />
        </Helmet>
      )}
      {children}
    </FindifyActiveContext.Provider>
  );
};

// FINDIFY PAGE VIEW TRACKING
export const FindifyTracker = ({ isProdPage, product, selectedVariation }) => {
  const findifyTrackingContext = useContext(FindifyTrackingContext);
  const location = useLocation();
  const { pathname } = location;

  const triggerFindifyTracking = async () => {
    const { analytics } = await waitForFindify();

    // REGULAR PAGES, JUST SEND VIEW PAGE EVENT
    if (!isProdPage) {
      analytics.sendEvent('view-page');
    }
    // PRODUCT PAGE - SEND MORE
    else {
      const baseItemId = product.articleNumber;

      // USE FIRST VARIANT IF NO SELECTED VARIANT
      // USE REGULAR ART NO AS VARIANT ID FOR PRODS WITHOUT VARIANTS
      let findifyVariantId = selectedVariation
        ? selectedVariation.articleNumber
        : null;
      if (!findifyVariantId) {
        if (product.variants?.values.length) {
          findifyVariantId = product.variants.values[0].articleNumber;
        } else {
          findifyVariantId = product.articleNumber;
        }
      }
      const trackingObject = {
        item_id: baseItemId,
        variant_item_id: findifyVariantId
      };

      analytics.sendEvent('view-page', trackingObject);
    }
  };

  useEffect(() => {
    if (pathname !== findifyTrackingContext.lastTrackedPath) {
      if (!isProdPage || product) {
        // STORE TRACKED PATHNAME
        findifyTrackingContext.setLastTrackedPath(pathname);

        // TRIGGER TRACKING FUNCTION
        triggerFindifyTracking();
      }
    }
  });
  return null;
};

// FINDIFY CART TRACKER, CLUNKY SETUP B/C CART ITEM STATE
// JUMPS AROUND BEFORE SETTLING ON NEW VALUE
let cartItemQtyCounter = 0;
let timeoutVar = null;
export const FindifyCartTracker = ({ cartData }) => {
  clearTimeout(timeoutVar);
  timeoutVar = setTimeout(function() {
    if (cartData && cartData.totalQuantity !== cartItemQtyCounter) {
      cartItemQtyCounter = cartData.totalQuantity;
      triggerFindifyCartTracking(cartData.items);
    }
  }, 1000);
  return null;
};

const triggerFindifyCartTracking = async cartItems => {
  let lineItems = [];
  cartItems.forEach((item, index) => {
    const baseItemId = item.articleNumber.substr(
      0,
      item.articleNumber.lastIndexOf('-')
    );

    lineItems.push({
      item_id: baseItemId,
      quantity: item.quantity,
      unit_price: item.product.price.incVat,
      variant_item_id: item.articleNumber
    });
  });

  const { analytics } = await waitForFindify();
  analytics.sendEvent('update-cart', {
    line_items: lineItems
  });
};

// TRACKING CONTEXT - FOR AVOIDING MULTIPLE IDENTICAL VIEW PAGE EVENTS BEING SENT
const FindifyTrackingContext = React.createContext(null);

const FindifyTrackingProvider = ({ children }) => {
  const [lastTrackedPath, setLastTrackedPath] = useState('');
  const history = useHistory();

  const goToRoute = route => {
    history.push(route);
  };

  useEffect(() => {
    window.goToRoute = goToRoute;
  });

  return (
    <FindifyTrackingContext.Provider
      value={{ lastTrackedPath, setLastTrackedPath }}
    >
      <FindifyLoader children={children} />
    </FindifyTrackingContext.Provider>
  );
};

export default FindifyTrackingProvider;
